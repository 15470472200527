<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>						
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="250" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="success" size="mini">详情</el-button>	
							<el-button @click="fagao(scope.row)" type="warning" size="mini">新增发言</el-button>	
							<el-button @click="zhuanfa(scope.row)" type="primary" size="mini">选择发言人</el-button>	
						</template>
					</MyTable>
				</div>
			</div>
		</div>	
		
		<tz_detail ref="tz_detail"></tz_detail>
		
		
		<!--转发弹窗-->
		<el-dialog title="选择发言人" :visible.sync="showzf" width="30%">
			<div class="addbox">
				<div class="left_info" style="margin-bottom: 20px;">
					<div style="display: flex;align-items: center;">
						<el-button type="primary" size="small" @click="select">选择</el-button>								
					</div>
					<div>
						<span style="color: #999;">已选人员：</span>{{user_name}}
					</div>
				</div>
			</div>
			<div style="width: 100%;display: flex;align-items: center;justify-content: flex-end;padding-top: 20px;border-top: 1px solid #eee;">
				<el-button type="" size="small" class="font14" @click="showzf=false">关闭</el-button>
				<el-button type="primary" size="small" class="font14" @click="surezf">确认</el-button>
			</div>
		</el-dialog>
		
		
		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
	</div>
</template>

<script>
	import selectuser from '@/components/select_user.vue';
	import tz_detail from '../components/tz_detail.vue';
	export default{
		components:{ tz_detail,selectuser },
		data() {
			return{
				showzf:false,
				searchinput:'',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},					
					{
						prop: 'topics_name',
						name: '大会发言征集标题',
						width: ''
					},
					{
						prop: 'wymeeting_name',
						name: '所属会议',
						width: ''
					},
					{
						prop: 'user_name',
						name: '发起人',
						width: '200'
					},
					{
						prop: 'addtime',
						name: '截止时间',
						width: '200'
					}
				],
				tableData: [],
				now_czid:'',
				user_id: '',
				user_name: '',
			}
		},
		mounted() {
			this.gettable()
		},
		methods:{
			fagao(row){
				this.$router.push({path:'/system/talk_fg_add',query:{id:row.topics_id,tz_id:row.id}})
			},
			//转发
			surezf(){
				if(!this.user_id){
					this.$message.warning('请选择一人')
					return
				}
				let userlength = this.user_id.split(',')
				if(userlength.length>=2){
					this.$message.warning('只能选择一人，请重新选择')
					return
				}
				this.$post({
					url: '/api/topicstz/edit',
					params: {
						zuser_id:this.user_id,
						id:this.now_czid
					}
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.showzf=false
				})				
				
			},
			zhuanfa(row){
				this.user_id = ''
				this.user_name = ''
				this.now_czid = row.id
				this.showzf = true
			},
			selectedry(e){
				this.user_id = e.map((item)=>{ return item.id })
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item)=>{ return item.name })
				this.user_name = this.user_name.join(',')
			},
			select(){
				let cxidlist = this.user_id?this.user_id.split(','):[]
				let cxnamelist = this.user_name?this.user_name.split(','):[]
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}				
				this.$refs.myselectuser.isdanxuan = true
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			showeidt(row){
				let obj = {
					name:row.topics_name,
					content:row.content,
					endtime:row.endtime,
					fujian:row.fujian,
					user_name:row.user_name,
					wymeeting_name:row.wymeeting_name
				}
				this.$refs.tz_detail.info = obj
				this.$refs.tz_detail.dialogVisible = true
			},
			changswitch(row){
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search(){
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable(){
				this.$post({
					url: '/api/topicstz/appindex',
					params: {
						name:this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {		
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew(){
				this.$router.push('/system/talk_list_add')
			},
			showdate(row){
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'talk_tz.scss';
</style>